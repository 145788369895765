import { styled } from "styled-components";

export const Contact = styled.div`
  color: #ffffff;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  padding: 20px 100px;

  @media screen and (max-width: 960px) {
    font-size: 1em;
    padding: 20px 50px;
  }

  @media screen and (max-width: 400px) {
    padding: 20px 30px;
  }
`;

export const Legalese = styled.div`
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-left: 39.5%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-left: 13%;
  }
`;

export const ContentHolder = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Address = styled.div`
  width: 300px;
  text-align: justify;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const AgreementLink = styled.a`
  padding: 20px 0px;
  width: 200px;
  text-decoration: none;
  color: #939393;
  @media screen and (max-width: 900px) {
    padding-bottom: 20px;
    margin-left: -8%;
  }

  @media screen and (max-width: 762px) {
    margin-left: -2%;
  }
  
  span:hover{
    color: #fc1481;
    filter: drop-shadow(0px -2px 10px rgba(233, 223, 255, 0.4))
      drop-shadow(0px -2px 40px rgba(187, 155, 255, 0.16));
  }
`;

export const Copyright = styled.div`
  text-align: center;
  color: #ffffff;
  padding: 10px;
`;

export const ContentColumn = styled.div`
    flex-direction: column;
`;

export const SocialIcon = styled.div`
  display: inline-block;
  color: #ffffff;
  font-family: 'Avenir';
  margin-bottom: 50px;

  span {
    font-size: 24px;
    width: 150px;
  }

  .social-icon {
    font-size: 24px;
    cursor: pointer;
  }
  `;

  export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
