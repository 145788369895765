import React from 'react';
import Blob from "../../../../assets/images/Blob.png";
import CommentRoundSvg from "../../../../assets/images/comment-round.svg";
import DiamondSvg from "../../../../assets/images/diamond.svg";
import laptopImg7 from "../../../../assets/images/Media 7.jpg";
import laptopImg1 from "../../../../assets/images/Media 1.jpg";
import laptopImg3 from "../../../../assets/images/Media 3.jpg";
import laptopImg4 from "../../../../assets/images/Media 4.jpg";
import { CommentImg, DiamondImg, ImageGridContainer, ImageContainer, Image, PreviewContainer } from './Elements';

const PlatformPreview = () => {
    return (
        <PreviewContainer>
            <ImageGridContainer>
                <ImageContainer style={{borderTopLeftRadius: '10px' }}>
                    <Image style={{ borderTopLeftRadius: '10px'}} src={laptopImg7} alt="Image 1" />
                </ImageContainer>
                <ImageContainer style={{borderTopRightRadius: '10px' }}>
                    <Image style={{ borderTopRightRadius: '10px' }} src={laptopImg1} alt="Image 2" />
                </ImageContainer>
                <ImageContainer style={{borderBottomLeftRadius: '10px' }}>
                    <Image style={{ borderBottomLeftRadius: '10px' }} src={laptopImg3} alt="Image 3" />
                </ImageContainer>
                <ImageContainer style={{borderBottomRightRadius: '10px' }}>
                    <Image style={{ borderBottomRightRadius: '10px' }} src={laptopImg4} alt="Image 4" />
                </ImageContainer>
            </ImageGridContainer>
            <CommentImg src={CommentRoundSvg} />
            <DiamondImg src={DiamondSvg} />
        </PreviewContainer>
    );
}

export default PlatformPreview;
