import styled from "styled-components";
import { FlexCenterContainer, FlexColumnContainer, Span } from '../../common/Elements'

export const SectionContainer = styled(FlexCenterContainer)`
  justify-content: space-evenly;
  padding: 50px 70px;

  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 20px 40px 40px 40px;
  }

  @media screen and (max-width: 500px) {
    padding: 20px;
  }
`;

export const ContentHolder = styled(FlexColumnContainer)`
  flex: 1;
  max-width: 520px;
  margin-right: 50px;

  @media screen and (max-width: 1300px) {
    margin-right: 0;
    padding: 40px;
    max-width: none;
  }

  @media screen and (max-width: 500px) {
    padding: 20px;
  }
`;

export const Header = styled(Span)`
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 50px;
  letter-spacing: 1px;

  @media screen and (max-width: 960px) {
    font-size: 36px;
    line-height: 30px;
  }

  @media screen and (max-width: 500px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

export const Paragraph = styled.p`
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.75px;
  line-height: 2;
  text-indent: 50px;
  text-align: justify;

  @media screen and (max-width: 1250px) {
    line-height: 1.5;
  }

`;
