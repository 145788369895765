import React from 'react';
import Blob from "../../../../assets/images/Blob.png";
import Building from "../../../../assets/images/building.png";
import { AboutUsBlobImg, AboutUsPreviewContainer, BuildingImg } from './Elements';

const AboutUsPreview = () => {
    return (
        <AboutUsPreviewContainer>
            <AboutUsBlobImg src={Blob} />
            <BuildingImg src={Building} />
        </AboutUsPreviewContainer>
    )
}

export default AboutUsPreview;
