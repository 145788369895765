import React, { useState } from "react";
import CustomDropdown from "../Dropdown";
import { SearchBar } from "../../SearchBar";
import {
  Card,
  Background,
  StyledParagraph,
  BillboardText,
  SearchButton,
  SectionContainer,
  SmallText,
} from "./Element";

const Section1 = () => {
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  
  return (
    <Background id="home">
      <StyledParagraph style={{ marginTop: "10%"}}>
      Unlock the power of advertising with <br></br>easy buying and selling across all billboard types.
      </StyledParagraph>
      <SectionContainer>
        <Card >
          <StyledParagraph style={{ fontSize: "2.2rem" }}>
            Explore <BillboardText>Billboard</BillboardText> Now
          </StyledParagraph>
          <CustomDropdown type={type} setType={setType} />
          <SearchBar setSearch={setSearch} />
          <SearchButton to={`/billboards${search.length > 0 ? `?search=${search}` : ''}${type?.length > 0 ? `${search.length > 0 ? '&' : '?'}type=${type}` : ''}`}>
            Search
          </SearchButton>
          <SmallText>
            By proceeding, I agree to the
            <BillboardText href="https://snipeads.com/privacy-policy/"> Privacy Policy</BillboardText> and
            <BillboardText href="https://snipeads.com/terms-and-conditions/"> Terms and Conditions</BillboardText>
          </SmallText>  
        </Card>
      </SectionContainer>
    </Background>
  );
};

export default Section1;
