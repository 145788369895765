import React from "react";
import { FlexColumnContainer } from "../common/Elements";
import Email from "../../assets/images/email.png";
import { Input } from "../Home/Subscription/Element";
import { Submitbtn } from "../Home/Contact/Element";
import {Container} from "./Element";

const UpdateComponent = () => {
    return(
        <Container>
            <img src={Email} alt="email" style={{paddingRight: "50px"}}></img>
            <FlexColumnContainer>
            <label style={{color: "#ffffff"}} htmlFor="email">Get Updates in Snipeads  by Email</label>
            <Input type="text"
                id="email"
                name="email"></Input>
            </FlexColumnContainer>
            <span style={{ paddingLeft: "20px" }}>
                <Submitbtn style={{marginTop: "50px", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }}>Email Me Updates</Submitbtn>
            </span>
        </Container>
    );
}

export default UpdateComponent;
