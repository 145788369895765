import styled from "styled-components";
import billboardImg from '../../assets/images/billboard_example.png'
import { Div, FlexCenterContainer, FlexColumnContainer, FlexContainer, FlexSpaceBetweenContainer } from "../common/Elements";
import { Link as LinkR } from "react-router-dom";
import { IoIosStar } from "react-icons/io";
import { GiShoppingCart } from "react-icons/gi"; 
import Slider from "react-slick";



export const MapContainer = styled.div`
  text-align: none;
  background-image: url(${billboardImg});
  @media screen and (max-width: 900px) {
    height: 35vh;
  }
`;

export const FlexBetweenContainer = styled(FlexCenterContainer)`
  margin-top: 100px;
  justify-content: space-between;
  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`;

export const FooterBtnContainer = styled(Div)`
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;

  @media screen and (max-width: 400px) {
    grid-template-columns: auto;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const BBItemWrapper = styled(FlexContainer)`
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    white-space: nowrap;
  }
`

export const DetailsWrapper = styled(Div)`
  padding: 40px 5% 5% 5%;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 80px;
  color: white;

  @media screen and (max-width: 1200px) {
    grid-template-columns: auto;
  }
`;

export const BBInfoContainer = styled(FlexColumnContainer)`
  border-radius: 5px;
  padding-top: 50px;
`;

export const BBInfoGrid = styled(Div)`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 40px;
  row-gap: 20px;

  @media screen and (max-width: 500px) {
    grid-template-columns: auto;
  }
`;

export const BBInfoColSpan = styled(FlexColumnContainer)`
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: justify;

  @media screen and (max-width: 500px) {
    grid-column-end: 2;
  }
`;

export const Border = styled.div`
  border-bottom: 2px #e9e9e9 solid;
  padding-top: 10px;
`;

export const LatLong = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Longitude = styled(FlexColumnContainer)`
  flex: 1;
  padding-left: 20px;
`;

export const Engaging = styled(FlexContainer)`
  justify-content: space-between;
  padding-top: 10px;
`;

export const MediaWrapper = styled(FlexColumnContainer)`
  padding-top: 20px;
`;

export const Categories = styled(FlexColumnContainer)`
  padding-bottom: 20px;
`;


export const AddressWrapper = styled(FlexColumnContainer)`
`;

export const Address = styled.div`
  font-size: 1.5em;
  padding-bottom: 10px;
`;

export const FullAddress = styled.div`
width: 500px;
  font-size: 2em;
  padding-bottom: 20px;
  @media screen and (max-width: 900px) {
    font-size: 1em;
  }
`;

export const Title = styled.span`
  font-size: 1.5em;
  padding: 15px 0px;
  text-transform: uppercase;
  @media screen and (max-width: 900px) {
    font-size: 1em;
  }
`;

export const Value = styled.span`
  color: #fc1481;
  padding-top: 10px;
  font-size: 1.2em;
  @media screen and (max-width: 900px) {
    font-size: 1em;
  }
`;

export const Location = styled.span`
  padding-bottom: 10px;
`;

export const Direction = styled.span`
  padding-bottom: 10px;
`;

export const PricingAreaContainer = styled(FlexContainer)`
  padding: 30px 20px 0px 20px;

  border-radius: 13px;
  box-shadow: inset 0px 0.5px 0px rgba(255, 255, 255, 0.5);
  border: none;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: drop-shadow(0px -2px 10px rgba(233, 223, 255, 0.4)) drop-shadow(0px -2px 40px rgba(187, 155, 255, 0.16));
`;

export const PhotoContainer = styled.div`
  flex: 1;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DetailPhoto = styled.img`
  padding: 25px;
  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 5px;
  }
`;

export const Downloadbtn = styled.button`
  border: none;
  padding-right: 30px;
  justify-content: flex-end;
  border-radius: 10px;
  margin-top: 20px;
  background: #fc1481;
  width: max-content;
  padding: 15px 75px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  align-self: center;
  transition: background-color 0.3s ease; /* Add transition for smoother color change */

  /* Change background color on hover */
  &:hover {
    background-color: #ff6ab4; /* Change to desired color */
  @media screen and (max-width: 900px) {
    white-space: nowrap;
  }
`;

export const Sharebtn = styled.button`
  background: transparent;
  padding-right: 30px;
  justify-content: flex-end;
  border: 1px #fc1481 solid;
  border-radius: 10px;
  margin-top: 20px;
  width: max-content;
  padding: 15px 110px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  align-self: center;
  transition: background-color 0.3s ease; /* Add transition for smoother color change */

  /* Change background color on hover */
  &:hover {
    background-color: #A472A1; /* Change to desired color */
`;

export const Image = styled.img`
  @media screen and (max-width: 900px) {
    width: 92%;
    padding: 20px 15px;
  }
`;

export const ImageHolder = styled.div`
  @media screen and (max-width: 900px) {
    display: flex;
  }
`;

export const Surroundings = styled.div`
  margin: 70px 0px 50px 50px;
  display: flex;
  @media screen and (max-width: 900px) {
    margin: 0px;
    flex-direction: column;
  }
`;

export const Content = styled.span`
  font-size: 2em;
  @media screen and (max-width: 900px) {
    margin-left: 20px;
    font-size: 1.5em;
    padding-top: 30px;
  }
`;

export const SimilarItemsWrapper = styled(FlexColumnContainer)`
  padding-top: 50px;
`;

export const SimilarItemsList = styled(Div)`
  display: grid;
  grid-template-columns: auto;
  row-gap: 20px;
  padding: 20px 0;
`;

export const FlexWrapper = styled(FlexContainer)`
@media screen and (max-width: 900px) {
  white-space: nowrap;
}
`;

export const SocialIcon = styled.img`
 padding-right: 10px;
`;

export const ButtonText = styled.span`
 font-size: 1.2em;
`;

export const StyledDescription = styled.span`
 padding-top: 7px;
 font-size: 1.2em;
 @media screen and (max-width: 900px) {
  font-size: 1em;
}
`;

export const SocialIconWrapper = styled.div`
  @media screen and (max-width: 560px) {
    padding-top: 20px;
    align-self: center;
  }
`;

export const ViewMoreBtn = styled(LinkR)`
  border: none;
  padding-right: 30px;
  justify-content: flex-end;
  border-radius: 10px;
  margin: 20px;
  background: #fc1481;
  width: max-content;
  padding: 10px 75px;
  color: white;
  align-self: center;
  text-decoration: none;
  @media screen and (max-width: 900px) {
    white-space: nowrap;
  }
`
export const SimilarItemImgContainer = styled(FlexCenterContainer)`
  max-width: 350px;
  width: 35%;
`;

export const SeeMoreBtn = styled(LinkR)`
  border-radius: 10px;
  background-color: #fc1481;
  margin-top: 20px;
  padding: 15px 20px;
  color: white;
  text-decoration: none;
  text-align: center;
`;

//NEW CSS Styling

// Wrapper styling
export const Wrapper = styled.div`
  display: flex;
  padding: 40px 0 20px 100px;
  gap: 25px;
  height: auto;

  @media screen and (max-width: 762px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    gap: auto;
    padding: 20px ;
    height: auto;
  }
`;

// Top
export const Top = styled.div`
  display: flex;
  width: 100%;
  gap: 600px;
  margin-top: 10px;

  @media screen and (max-width: 900px) {
    gap: 50px;
  }
`;

// Pagination
export const PageNav = styled.div`
  color: white;
  font-size: 1.3em;
  display: flex;
  height: 30px;
  font-size: 1em;
  margin: 18px 0 0 10px;
  margin-left: 10%;

  @media screen and (max-width: 900px) {
    font-size: 0.7em;
  }
`;

// add to cart
export const Cart = styled.div`
  display: flex;
  color: #fc1481;
  width: 125px;
  height: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1em;
  border: 1px solid #fc1481;
  margin: 18px 0 0 10px;
  border-radius: 10px;

  .icon {
    margin-left: 5px;
    font-size: 1.5em;
    color: #fc1481;
  }

  @media screen and (max-width: 900px) {
    width: 105px;
    height: 30px;
    font-size: 0.7em;
    margin: auto;
  }
`;

// Images styling
export const Images = styled.img`
  display: flex;
  padding-left: 20px;
  width: 45%;  
  height: 75%;
  object-fit: cover; 

  @media screen and (max-width: 900px) {
    width: 50%;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 50%;
  height: 575px;
  margin-left: 30px;

  @media screen and (max-width: 900px) {
    width: 80%;
    height: 375px;
  }
`;

export const SlideImage = styled.img`
  width: 100%;
  height: 550px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 400px;
  }

  @media (max-width: 480px) {
    height: 250px;
  }
`;

export const SliderArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  font-size: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  transition: background-color 0.3s ease;
  border-radius: 50%;
  text-align: center; /* Center text horizontally */
  line-height: 40px; /* Center text vertically */

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }

  &.prev {
    left: 10px;
  }

  &.next {
    right: 10px;
  }

  @media screen and (max-width: 900px) {
    top: 30%;
  }
`;



// BBInfo styling
export const BBInfo = styled.div`
  padding-left: 10px;
`;

export const BBScreen = styled.div`
  color: white;
  font-size: 1.3em;
  display: flex;
`;

export const BBScreenID = styled.div`
  color: white;
  font-size: 1.0em;
  margin-top: 2%;
  display: flex;
`;

export const StarRate = styled.div`
  display: flex;
  align-items: center;
`;

export const Star = styled(IoIosStar)` 
  margin-top: 2%;
  color: #fc1481;
  margin-right: 5px; 
  transform: scaleX(0.8);
`;

export const BBReview = styled.div`
 display: flex;
  color: #fc1481;
  font-size: 1.0em;
  margin-top: 2%;
  margin-left: 1%;
`;

export const BBLine = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  color: grey;
  
`;

export const BBAddress = styled.div`
  display: flex;
  color: white;
  font-size: 1.5em;
  line-height: 160%;
  width: 70%;
`;

export const BBPriceLine = styled.div`
  display: grid;
  grid-template-columns: 100px 100px auto;
  margin-top: 15px;
  width: 50%;
`;

export const BBPrice = styled.div`
  display: flex;
  color: #F57D1F;
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 2%;
`;

export const BBDiscount = styled.div`
  display: flex;
  color: #393646;
  justify-content: right;
  font-size: 1em;
  font-weight: bold;
  margin-top: 2%;
  margin-left: 5%;
  text-decoration-line: line-through;
  text-decoration-style: double;
`;

export const BBDiscountCard = styled.div`
  display: flex;
  color: #F57D1F;
  width: 35px;
  height: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.7em;
  border: 1px solid orange;
  margin: 6px 0 0 10px;
`;

export const BBTypeHead = styled.div`
  display: flex;
  color: White;
  font-size: 1.5em;
  line-height: 160%;
  width: 70%;
`;

export const BBType = styled.div`
  display: flex;
  margin-top: 2%;
  color: white;
  font-size: 1.1em;
  line-height: 160%;
`;

export const MediaStatus = styled.div`
  display: flex;
  color: White;
  font-size: 1.5em;
  line-height: 160%;
  width: 70%;
`;

export const MediaInfo = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 60px;
  color: white;
`;

export const TrafficVolume = styled.div`

`;

export const TrafficVolumeText = styled.div`

  margin-top: 10px;
  span{
    color: #fc1481;
  }
`;

export const MediaAvailability = styled.div`
  span{
    color: #fc1481;
  }
`;

export const Description = styled.div`
  display: flex;
  color: White;
  font-size: 1.5em;
  line-height: 160%;
  width: 70%;
`;

export const LocationInfo = styled.div`
  display: flex;
  color: White;
  font-size: 0.8em;
  line-height: 160%;
  width: 70%;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const DirectionInfo = styled.div`
  display: flex;
  color: White;
  font-size: 0.8em;
  line-height: 160%;
  width: 80%;
  margin-bottom: 12px;

`;

export const NearbyInfo = styled.div`
  display: flex;
  color: White;
  font-size: 0.8em;
  line-height: 160%;
  width: 70%;
`;