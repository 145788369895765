import styled from "styled-components";
import { Header, SectionContainer } from "../Section2/Element";
import { Div, FlexCenterContainer, Img } from "../../common/Elements";
import { AboutUsContentContainer } from "../Section4-AboutUs/Element";

export const MediaOwnerContainer = styled(SectionContainer)`
  flex-direction: column;
`;

export const Divider = styled(Div)`
  width: 50%;
  background-color: #FC1481;
  height: 3px;
  margin: 30px 0;

  @media screen and (max-width: 960px) {
    width: 70%;
  }
`;

export const SubHeader = styled(Header)`
  font-size: 28px;
  line-height: 26px;
  letter-spacing: 1px;
  text-align: center;
  line-height: 3;

  @media screen and (max-width: 960px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

export const SubHeaderS = styled(SubHeader)`
  padding: 30px 0;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
  line-height: 2.2;

  @media screen and (max-width: 960px) {
    font-size: 12px;
  }
`;

export const MediaOwnerBody = styled(FlexCenterContainer)`
  justify-content: space-evenly;
  width: 90%;
  
  @media screen and (max-width: 1350px) {
    width: 100%;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

// Define a container for the grid
export const ImageGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0; 
  width: 200px;
  height: auto;
  flex-grow: 1;
  box-shadow: inset 0px 0.5px 0px rgba(255, 255, 255, 0.5);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: drop-shadow(0px -2px 10px rgba(233, 223, 255, 0.4))
    drop-shadow(0px -2px 40px rgba(187, 155, 255, 0.16));
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media screen and (max-width: 960px) {
    width: auto;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  `;

// Define individual grid items
export const ImageContainer = styled.div`
  margin-right : 30%;
  width: 100%;
  box-sizing: border-box;
  border: 1.4px solid black; /* define a thin border */
  border radius: 10px;
  box-shadow: inset 0px 0.5px 0px rgba(255, 255, 255, 0.5);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: drop-shadow(0px -2px 10px rgba(233, 223, 255, 0.4))
    drop-shadow(0px -2px 40px rgba(187, 155, 255, 0.16));
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

// Define the image style
export const Image = styled.img`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  object-fit: cover;

`;

export const MediaOwnerContentsContainer = styled(AboutUsContentContainer)`
  max-width: 700px;
`;

export const MediaOwnerPreviewContainer = styled(FlexCenterContainer)`

  @media screen and (max-width: 960px) {
      margin-top: 0px;
      padding: 40px;
      margin-right: 0;
  }

  @media screen and (max-width: 600px) {
    padding: 25px;
  }
`;

export const MediaOwnerPreviewImg = styled(Img)`
  width: 90%;
  min-width: 400px;

  @media screen and (max-width: 500px) {
    min-width: 0;
    width: 70%;
  }
`;

export const ContactBtn = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 15px;
  background: #fc1481;
  width: 300px;
  padding: 15px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    width: 180px;
    align-self: center;
  }
`;