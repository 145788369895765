import { BrowserRouter as Router, Route, Routes as Switch } from 'react-router-dom';
import Home from './pages/Home';
import Billboards from './pages/Billboards';
// import ScrollToTop from './components/common/ScrollToTop';

import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import BillboardDetail from './pages/BillboardDetail';

function App() {
  return (
    <Router>
      {/* <ScrollToTop> */}
        <Switch>
          <Route path='/' element={<Home />} exact />
          <Route path='/billboards' element={<Billboards />} exact />
          <Route path='/billboard/:id' element={<BillboardDetail />} exact />
        </Switch>
      {/* </ScrollToTop> */}
    </Router>
  );
}

export default App;
