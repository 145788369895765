import styled from "styled-components";
import backgroundImg from "../../../assets/images/background.svg";
import formBackground from "../../../assets/images/form-bg.jpg";
import { Div, FlexCenterContainer, FlexColumnContainer, FlexContainer, Span } from "../../common/Elements";
import { TextareaAutosize } from "@mui/material";
import { Link } from "react-router-dom";

export const FormBaseContainer = styled(FlexContainer)`
  background-image: url(${backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 520px) {
    padding: 30px 20px;
  }
`;

export const FormContainer = styled(FlexContainer)`
  padding: 30px;
  flex: 1 1 auto;
`;

export const FormLeftContainer = styled(FlexColumnContainer)`
  flex: initial;
  background-image: url(${formBackground});
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  padding-right: 200px;

  @media screen and (max-width: 1400px) {
    padding-left: 20px;
    padding-right: 40px;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const LeftInfoText = styled(Span)`
  color: white;
  font-family: "Avenir";
  font-style: normal;
  font-size: 20px;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; /* Change font family */
`;

export const SocialIcons = styled(FlexCenterContainer)`
  justify-content: space-between;
  padding: 20px 25px;
`;

export const FormRightContainer = styled(FlexColumnContainer)`
  flex: 1;
  background-color: white;
  padding: 70px;

  @media screen and (max-width: 1400px) {
    padding: 70px 30px;
  }

  @media screen and (max-width: 600px) {
    padding: 40px 30px;
  }
`;

export const FormRightLabel = styled(LeftInfoText)`
  color: #000000;
  padding: 0;
`;

export const GridContainer = styled(Div)`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 30px;
  row-gap: 20px;
  padding-top: 20px;

  @media screen and (max-width: 700px) {
    grid-template-columns: auto;
  }
`;

export const InputContainer = styled(FlexColumnContainer)`
`;

export const InputLabel = styled(FormRightLabel)`
  font-size: 14px;
  padding: 10px 0;
`;

export const MessageTextArea = styled(TextareaAutosize)`
  padding: 20px;
  border: 1px solid #CBCBCB;
  border-radius: 5px;
  font-family: inherit; /* Use the same font family as other input elements */
  font-size: 14px; /* Set the font size to match other input elements */
`;

export const ActionContainer = styled(FlexContainer)`
  justify-content: flex-end;
`;

export const Submitbtn = styled(Link)`
  border: none;
  padding-right: 30px;
  justify-content: flex-end;
  border-radius: 5px;
  margin-top: 20px;
  background: #fc1481;
  width: max-content;
  padding: 15px 20px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; /* Change font family */
`;


export const Container = styled.div`
  display: flex;
  width: 86%;
  height: 70vh;
  padding: 10vh;
  @media screen and (max-width: 900px) {
    padding: 0px;
    height: 100vh;
  }
`;

export const Contact = styled.div`
  flex: 1;
  background-image: url(${formBackground});
  padding: 20px 0px 0px 30px;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const Form = styled.div`
  flex: 3;
  background: #fff;
  padding: 20px 10px 0px 30px;
`;

export const P = styled.p`
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
`;

export const Img = styled.img`
  padding-right: 15px;
`;

export const Field1 = styled.div`
  display: flex;
  margin-top: 10px;
  padding-bottom: 10px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const FName = styled.div`
  flex: 1;
`;

export const LName = styled.div`
  flex: 1;
`;

export const Input = styled.input`
  width: 90%;
  height: 40px;
  border: 1px #d1d5db solid;
  border-radius: 5px;

  &:focus {
    border-color: 1px #d1d5db solid;
    outline: none;
  }
  @media screen and (max-width: 900px) {
    height: 30px;
  }
`;

export const A = styled.a`
  padding-right: 40px;
`;

export const Textarea = styled.textarea`
  width: 95%;
  border: 1px #d1d5db solid;
  border-radius: 5px;
  margin-top: 5px;

  &:focus {
    border-color: 1px #d1d5db solid;
    outline: none;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 35px;

  @media screen and (max-width: 900px) {
    margin-right: 65px;
  }


`;

export const Subject = styled.input`
  width: 95%;
  height: 40px;
  border: 1px #d1d5db solid;
  border-radius: 5px;
  margin-top: 5px;
  @media screen and (max-width: 900px) {
    height: 30px;
  }
`;

export const Char = styled.span`
  color: #6b7280;
  @media screen and (max-width: 900px) {
    margin-left: 0px;
  }
`;

export const SuccessMessage = styled.div`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 10px;
`;