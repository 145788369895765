import React from 'react';
import { ApplyButton, BBCard, BBTopContainer, BootstrapInput, StateTextField } from './Elements';
import { BillboardText, StyledParagraph } from '../../Home/Section1/Element';
import { Icon, MenuItem, Select } from '@mui/material';
import { FlexContainer } from '../../common/Elements';
import PinIcon from '../../../assets/images/pin_pink.svg'
import SearchIcon from '@mui/icons-material/SearchRounded';
import { screenTypes, states } from '../../../common/constants';
import { useMediaQuery } from '@mui/material';

const TopSection = ({ 
    type, 
    search, 
    state, 
    setType, 
    setState, 
    setSearch, 
    onApplySearch 
}) => {
    const isMobile = useMediaQuery("(max-width:960px)");

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleStateChange = (event) => {
        setState(event.target.value);
    };

    const handleSearchValueChange = (event) => {
        setSearch(event.target.value);
    }

    return (
        <BBTopContainer>
            <BBCard>
                <StyledParagraph style={{ fontSize: "1.8rem", paddingTop: 0, marginTop: 0 }}>
                    Explore <BillboardText>Billboards</BillboardText> Now
                </StyledParagraph>
                <Select
                    style={{ width: '90%', background: 'white' }}
                    value={type}
                    onChange={handleTypeChange}
                >
                    <MenuItem value='' disabled>
                        Select Media Type
                    </MenuItem>
                    {
                        screenTypes.map(screenType => (
                            <MenuItem value={screenType.value}>
                                {screenType.label}
                            </MenuItem>
                        ))
                    }
                </Select>
                <FlexContainer style={{ width: '90%', padding: '20px 0', flexDirection: isMobile ? 'column-reverse' : '' }}>
                    <div style={{alignSelf: isMobile ? 'center' : '', paddingTop: isMobile ? '10px' : ''}}>
                    <img src={PinIcon} alt='pin icon'></img>
                    <Select
                        input={<BootstrapInput />}
                        value={state}
                        onChange={handleStateChange}
                    >
                        <MenuItem value="all">
                            All State
                        </MenuItem>
                        {
                            states.map(state => (
                                <MenuItem value={state.toLowerCase()}>
                                    {state}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    </div>
                    <StateTextField
                        value={search}
                        onChange={handleSearchValueChange}
                        startAdornment={<Icon style={{ color: 'white', paddingRight: '10px' }}><SearchIcon /></Icon>}
                        placeholder='Search Billboards'
                    />
                </FlexContainer>
                <ApplyButton variant='contained' onClick={(e) => onApplySearch(e.target.value)}> 
                    <span style={{ color: 'white', zIndex: 100 }}>Apply</span> 
                </ApplyButton>
                
            </BBCard>
        </BBTopContainer >
    )
}

export default TopSection;