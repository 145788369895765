import { styled } from "styled-components";
import { FlexContainer } from "../../../common/Elements";

export const PreviewContainer = styled(FlexContainer)`
    justify-content: center;
    position: relative;
    max-width: 600px;

    @media screen and (max-width: 960px) {
        max-width: 500px;
    }

    @media screen and (max-width: 600px) {
        padding: 20px;
    }
`;

const Img = styled.img``;

export const BlobImg = styled(Img)`
    opacity: 20%;
    width: 90%;
`;

export const PlatformImg = styled(Img)`
  position: absolute;
  width: 90%;
`;

export const CommentImg = styled(Img)`
    position: absolute;
    background-color: #FC1481;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    padding: 16px;
    top: -30px;
    right: -20px;

    @media screen and (max-width: 600px) {
        padding: 10px; width: 30px; height: 30px; top: 1%; right: 3px; 
    } 

    @media screen and (max-width: 400px) {
        padding: 8px; width: 25px; height: 25px; top: 1%; right: 4px; 
    }
`;

export const DiamondImg = styled(Img)`
    position: absolute;
    background-color: #610BEF;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    padding: 16px;
    bottom: -45px;
    left: -30px;
    
    @media screen and (max-width: 600px) {
        padding: 10px; width: 30px; height: 30px; bottom: 1%; left: 3px; 
    } 

    @media screen and (max-width: 400px) {
        padding: 8px; width: 25px; height: 25px; bottom: 1%; left: 4px; 
    }
`;

// Define a container for the grid
export const ImageGridContainer = styled.div`
    display: grid;
    width: auto; /* Allow the container to adjust its width based on content */
    max-width: 550px;
    grid-template-columns: repeat(2, 1fr);
    flex-grow: 1;
    box-shadow: inset 0px 0.5px 0px rgba(255, 255, 255, 0.5);
    background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
    );
    filter: drop-shadow(0px -2px 10px rgba(233, 223, 255, 0.4))
    drop-shadow(0px -2px 40px rgba(187, 155, 255, 0.16));
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @media screen and (max-width: 960px) {
        width: auto;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    `;

// Define individual grid items
export const ImageContainer = styled.div`
    margin-right : 30%;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid black;
    box-shadow: inset 0px 0.5px 0px rgba(255, 255, 255, 0.5);
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.05) 0%,
        rgba(255, 255, 255, 0) 100%
    );
  filter: drop-shadow(0px -2px 10px rgba(233, 223, 255, 0.4))
    drop-shadow(0px -2px 40px rgba(187, 155, 255, 0.16));
    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    `;

// Define the image style
export const Image = styled.img`
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: block;
    object-fit: cover;

    @media screen and (max-width: 768px) {
    `;
