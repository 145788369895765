import React from "react";
import { FaSearch } from "react-icons/fa";

export const SearchBar = ({ search, setSearch }) => {

  return (
    <div
      style={{
        marginTop: "15px",
        width: "auto",
        border: "1px #fff solid",
        borderRadius: "5px",
        height: "3.5rem",
        padding: "0px 15px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <FaSearch style={{ color: "white" }}></FaSearch>
      <input
        style={{
          backgroundColor: "transparent",
          border: "none",
          height: "100%",
          width: "100%",
          outline: "none",
          marginLeft: "15px",
          caretColor: "white",
          color: "white",
        }}
        placeholder="Search Keyword"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
};
