import { styled } from "styled-components";
import { FlexCenterContainer, FlexColumnCenterContainer } from "../../common/Elements";
import backgroundImg from "../../../assets/images/background.svg";
import { Button, InputBase, OutlinedInput } from "@mui/material";

export const BBTopContainer = styled(FlexCenterContainer)`
    background-color: #0b0121;
    background-size: cover;
    background-image: url(${backgroundImg});
    background-position: center;
    background-repeat: no-repeat;
    height: 60vh; 
`;

export const BBCard = styled(FlexColumnCenterContainer)`
    padding: 50px;
    margin: 20%;
    width: 90%;
    border-radius: 1.5rem;
    box-shadow: 1px 0.5px 8px rgba(255, 255, 255, 0.5);
    border: none;
    background: linear-gradient(
        190deg,
        rgba(255, 255, 255, 0.05) 100%,
        rgba(255, 255, 255, 0) 100%
    );
    filter: drop-shadow(0px -2px 10px rgba(233, 223, 255, 0.4))
        drop-shadow(0px -2px 40px rgba(187, 155, 255, 0.16));
    max-width: 700px;

    @media screen and (max-width: 680px) {
        padding: 30px;
        margin: 10%;
    }
`;

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '3px',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: 18,
        padding: '10px 26px 10px 12px',
        color: 'white',
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    '& .MuiSvgIcon-root': {
        color: 'white',
    }
}));

export const StateTextField = styled(OutlinedInput)({
    flex: 1,
    '& ,MuiInputBase-root': {
        '& fieldset': {
            borderColor: 'white'
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
    },
    '& .MuiOutlinedInput-input': {
        '& fieldset': {
            borderColor: 'white'
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
    },
    '& .MuiInputBase-input': {
        color: 'white',
    },
});

export const ApplyButton = styled(Button)({
    color: 'white',
    '& .MuiTouchRipple-root': {
        backgroundColor: '#FC1481',
        color: 'white'
    },
    '& ,MuiButtonBase-root': {
        background: '#FC1481',
        color: 'white'
    },
    '& ,MuiButton-root': {
        background: '#FC1481',
        color: 'white'
    },
    '& .MuiButtonContained-root': {
        background: '#FC1481',
        color: 'white'
    }, 
});