import React from "react";
import { FlexColumnContainer } from "../common/Elements";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4-AboutUs";
import Section5 from "./Section5";
import FormSection from "./Contact";
import Subscrption from "./Subscription";

const HomeComponent = () => {
  return (
    <FlexColumnContainer>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <FormSection />
      <Subscrption />
    </FlexColumnContainer>
  );
};

export default HomeComponent;
