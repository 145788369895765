import React, { useState } from 'react';
import BillboardLists from './BillboardLists';
import TopSection from './TopSection';
import { BBContainer } from './Elements';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { SCREENS_QUERY } from '../../schema/gqlSchema';

const BillboardsComponent = () => {
  const [params] = useSearchParams();
  const [search, setSearch] = useState(params.get('search') || '');
  const [type, setType] = useState(params.get('type') || '');
  const [state, setState] = React.useState('all');

  const { loading, data, error } = useQuery(
    SCREENS_QUERY,
    {
      variables: {
        search,
      },
      fetchPolicy: "network-only"
    }
  );

  const onApplySearch = () => {
    const filters = [];
    if (search?.length > 0) {
      filters.push(`search=${search}`);
    }
    if (type.length > 0) {
      filters.push(`type=${type}`);
    }
    if (state.length > 0) {
      filters.push(`state=${state}`);
    }
    window.location.href = `${window.location.href.split('?')[0]}${filters.length > 0 ? `?${filters.join('&')}`: ''}`;
  }

  return (
    <BBContainer>
      <TopSection 
        type={type} 
        search={search} 
        state={state}
        setState={setState}
        setType={setType}
        setSearch={setSearch}
        onApplySearch={onApplySearch} 
      />
      <BillboardLists 
        data={data} 
        loading={loading} 
        error={error} 
      />
    </BBContainer>
  )
}

export default BillboardsComponent