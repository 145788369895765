import React from "react";
import { Link } from "react-router-dom";
import { dashURL, routes } from "../../common/constants";
import Logo from "../common/Logo";
import { useMediaQuery } from "@mui/material";
import {
  Nav,
  NavContainer,
  NavMenu,
  NavItem,
  NavScrollLink,
  RegisterButton,
  MobileIcon,
  MenuIconBtn,
  MobileNavMenu,
} from "./Elements";

const NavBar = () => {
  const isMobile = useMediaQuery("(max-width:960px)");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleRedirect = (url) => {
    window.open(url);
  };

  const handleOnLinkClick = () => {
    const currentRoute = window.location.href.split("/");
    if (currentRoute.length > 3 && currentRoute[currentRoute.length - 1] !== "") {
      window.location.href = currentRoute.splice(0, 2).join('/');
    }
    setIsMobileMenuOpen(false);
  };

  const scrollToTop = () => {
    if (window.location.pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.location.href = "/";
    }
  };

  return (
    <Nav>
      <NavContainer>
      <Link to="/" onClick={scrollToTop} style={{ textDecoration: "none", cursor: "pointer" }}>
          <Logo />
        </Link>
        {isMobile ? (
          <>
            <MobileIcon onClick={toggleMobileMenu}>
              <MenuIconBtn />
            </MobileIcon>
            <MobileNavMenu isOpen={isMobileMenuOpen}>
              {routes?.map((route) => (
                <NavItem key={route.name}>
                  <NavScrollLink
                    to={route?.id}
                    smooth="true"
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    onClick={handleOnLinkClick}
                  >
                    {route.label}
                  </NavScrollLink>
                </NavItem>
              ))}
              {/* <RegisterButton onClick={() => handleRedirect(dashURL.register)}>Register</RegisterButton> */}
            </MobileNavMenu>
          </>
        ) : (
          <NavMenu>
            {routes?.map((route) => (
              <NavItem key={route.name}>
                <NavScrollLink
                  to={route?.id}
                  smooth="true"
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  onClick={handleOnLinkClick}
                >
                  {route.label}
                </NavScrollLink>
              </NavItem>
            ))}
            {/* <RegisterButton onClick={() => handleRedirect(dashURL.register)}>Register</RegisterButton> */}
          </NavMenu>
        )}
      </NavContainer>
    </Nav>
  );
};

export default NavBar;
