import { styled } from "styled-components";
import { FlexCenterContainer, Img } from "../../../common/Elements";

export const AboutUsPreviewContainer = styled(FlexCenterContainer)`
    position: relative;
    margin-top: -60px;

    @media screen and (max-width: 960px) {
        margin-top: 0px;
        margin-right: -50px;
        padding: 40px;
    }

    @media screen and (max-width: 860px) {
        margin-right: 0;
        margin-left: 20px;
        padding: 20px;
    }
`;

export const AboutUsBlobImg = styled(Img)`
    opacity: 20%;
    top: -40px;
    right: -30px;
    width: 80%;

    @media screen and (max-width: 960px) {
        right: -60px;
    }

    @media screen and (max-width: 720px) {
        right: 0;
    }
`;

export const BuildingImg = styled(Img)`
    position: absolute;
    top: 100px;
    left: -50px;
    width: 90%;

    @media screen and (max-width: 960px) {
        width: 80%;
        right: 50px;
        left: none;
        left: 0;
        top: 100px;
    }

    @media screen and (max-width: 600px) {
        top: 60px;
    }
`;
