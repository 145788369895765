import React from 'react';
import BillboardDetailComponent from '../components/BillboardDetails';
import { useParams } from 'react-router-dom';
import BaseComponent from '../components/common/BaseComponent';

const BillboardDetail = () => {
  const { id } = useParams();
  return (
    <BaseComponent>
      <BillboardDetailComponent id={id} />
    </BaseComponent>
  )
}

export default BillboardDetail