import React from 'react';
import { ApplyButton, ClearButton, FilterContainer, Label } from './Elements';
import { FlexColumnContainer, FlexContainer } from '../../../common/Elements';
import { Divider } from './Elements';
import { InputBase, Paper } from '@mui/material';

const FilterComponent = () => {
    return (
        <FilterContainer>
            <Label>FILTER BY:</Label>
            <FlexContainer style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <ClearButton>Clear All</ClearButton>
                <ApplyButton variant='contained'>Apply</ApplyButton>
            </FlexContainer>

            <Divider />

            <FlexColumnContainer>
                <Label>BUDGET</Label>
                <Paper>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                    />
                </Paper>
            </FlexColumnContainer>

            <Divider />

            <FlexColumnContainer>
                <Label>SIZE</Label>
                <Paper>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                    />
                </Paper>
            </FlexColumnContainer>

            <Divider />

            <FlexColumnContainer>
                <Label>TRAFFIC VOLUME</Label>
                <Paper>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                    />
                </Paper>
            </FlexColumnContainer>
            
            {/* <Divider /> */}

            {/* <FlexColumnContainer>
                <Label>INDUSTRY</Label>
                <FlexColumnContainer style={{ paddingLeft: '15px', paddingTop: '10px' }}>
                    <Label>INDUSTRY</Label>
                    <Label>INDUSTRY</Label>
                    <Label>INDUSTRY</Label>
                    <Label>INDUSTRY</Label>
                    <Label>INDUSTRY</Label>
                </FlexColumnContainer>
            </FlexColumnContainer> */}
        </FilterContainer>
    )
}

export default FilterComponent;