import { styled } from "styled-components";
import { FlexCenterContainer, FlexColumnContainer, FlexContainer } from "../../common/Elements";
import { Select } from "@mui/material";
import { Link as LinkR } from "react-router-dom";

export const BBListContainer = styled(FlexColumnContainer)`
    padding: 30px 40px;
    @media screen and (max-width: 960px) {
        padding: 30px 15px;
    }
`;

export const BBListSortView = styled(FlexContainer)`
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
`;

export const Text = styled.span`
    font-size: 18px;
    font-family: Avenir;
    color: white;
`;

export const ResultCountText = styled(Text)`
    color: #FC1481;
`;

export const VerticleLine = styled.div`
    background-color: #EAEAEA;
    height: 40px;
    width: 1.8px;
    @media screen and (max-width: 960px) {
        display: none;
    }
`;

export const SortBySelect = styled(Select)({
    '& ,MuiInputBase-root': {
        '& fieldset': {
            border: 'none'
        },
        '&:hover fieldset': {
            border: 'none'
        },
    },
    '& .MuiSvgIcon-root': {
        color: 'white',
        '& fieldset': {
            color: 'white'
        },
        '&:hover fieldset': {
            color: 'white'
        },
    }
});

export const BBItemContainer = styled(LinkR)`
    display: flex;
    flex-direction: column;
    border: 1px solid #EAEAEA;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    text-decoration: none;
    position: relative;
`;

export const HoverView = styled(FlexContainer)`
    position: absolute;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    background: white;
    border-radius: 10px;
    opacity: 0.3;
`;

export const BBItemContentContainer = styled(FlexContainer)`
    padding: 20px;

    @media screen and (max-width: 900px) {
        padding: 20px 15px;
    }

    @media screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const BBItemFooterContainer = styled(BBItemContentContainer)`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;

    @media screen and (max-width: 500px) {
        grid-template-columns: auto;
        row-gap: 20px;
        grid-auto-flow: dense;
        direction: btt;
    }
`;

// export const 

export const BBTagsContainer = styled(FlexContainer)`
    align-items: start;
    @media screen and (max-width: 960px) {
        flex-direction: column;
    }
`

export const PriceText = styled(Text)`
    color: #F18357;
    display: flex;
    align-items: center;
    flex: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const LocationText = styled(PriceText)`
    color: #FC1481;
    flex: 1;
    padding-left: 20px;
    text-align: left;
    align-items: flex-start;
    
    @media screen and (max-width: 900px) {
        font-size: 1em;
        padding-left: 0;
        padding-top: 15px;
    }
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const ImageContainer = styled(FlexCenterContainer)`
    max-width: 250px;
    width: 30%;

    @media screen and (max-width: 960px) {
        width: 50%;
    }

    @media screen and (max-width: 760px) {
        display: none;
    }
`;

export const BBImage = styled.img`
    object-fit: contain;
    width: 100%;
`;

export const BBAddressLabel = styled(Text)`
    font-size: 20px;
    margin-top: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const BBAddressText = styled(Text)`
    font-size: 16px;
    padding-top: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const Divider = styled.div`
    background-color: #EAEAEA;
    height: 1.5px;
    width: 100%;
`;

export const CategoryLabel = styled(Text)`
    font-size: 16px;
    flex: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const CategoryText = styled(Text)`
    font-size: 16px;
    background-color: #FC1481;
    padding: 1px 8px;
    border-radius: 8px;
    margin-right: 6px;
    margin-bottom: 6px;
    width: max-content;
    height: max-content;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const CategoryList = styled(FlexContainer)`
    flex-wrap: wrap;
`;
