import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Menu } from "@mui/icons-material";
import { Div } from "../../common/Elements";
import { Link as LinkS } from "react-scroll";
import { FaHome } from 'react-icons/fa';

export const Nav = styled(Div)`
  background: #0b0121;
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  @media screen and (max-width: 960px) {
    height: 65px;
  }
`;

export const NavContainer = styled(Div)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  padding: 0 100px;
  max-width: 1500px;
  color: white;
  @media screen and (max-width: 960px) {
    padding: 0 20px;
  }
`;

export const MobileIcon = styled(Div)`
  display: none;

  @media screen and (max-width: 960px) {
    display: flex;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const MenuIconBtn = styled(Menu)`
  color: #ffffff;
  cursor: pointer;
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin: 0;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 24px;
  margin: 0 20px;
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: transparent;
  white-space: nowrap;
  color: white;
  font-family: "Avenir";
  font-size: 18px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  position: relative;

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: left bottom left;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #ffffff;
    transform-origin: left bottom right;
    transition: transform 0.25s ease-out;
  }
`;

export const RegisterButton = styled.button`
  background: #0b0121;
  color: white;
  border-radius: 45px;
  border: 1px white solid;
  padding: 7px 20px;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #0b0121;
    background: white;
  }
`;

export const NavScrollLink = styled(LinkS)`
  border-radius: 50px;
  background: transparent;
  white-space: nowrap;
  color: #ffffff;
  font-weight: bolder;
  font-size: 18px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  position: relative;

  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: left bottom left;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #ffffff;
    transform-origin: left bottom right;
    transition: transform 0.25s ease-out;
  }
`;

export const MobileNavMenu = styled(NavMenu)`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin: 0;

  @media screen and (max-width: 960px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #0b0121;
    padding: 30px 0;
  }
`;

export const StyledHomeIcon = styled(FaHome)`
  margin-top: 5px;  
  width: 22px;  
  height: 20px; 
  color: inherit;
`;
