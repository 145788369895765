import React, { useState } from "react";
import logo3 from '../../assets/images/billboard_example.png'
import { GiShoppingCart } from "react-icons/gi";
import logo4 from "../../assets/images/Logo SistemPerintis.png";
import logo5 from "../../assets/images/Logo SkyBlue.png";
import logo6 from "../../assets/images/Logo SegiFresh.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Wrapper,
  Images,
  SliderContainer,
  BBInfo,
  BBScreen,
  BBScreenID,
  Star,
  StarRate,
  BBReview,
  BBLine,
  BBAddress,
  BBPrice,
  BBPriceLine,
  BBDiscount,
  BBDiscountCard,
  BBTypeHead,
  BBType,
  MediaStatus,
  MediaInfo,
  TrafficVolume,
  TrafficVolumeText,
  MediaAvailability,
  Description,
  LocationInfo,
  NearbyInfo,
  DirectionInfo,
  Top,
  Cart,
  PageNav,
  SliderArrow,
  SlideImage,
  // DetailsWrapper,
  // MapContainer,
  // Border,
  // LatLong,
  // Title,
  // Value,
  // Location,
  // Container,
  // PhotoContainer,
  // Downloadbtn,
  // Sharebtn,
  // AddressWrapper,
  // SocialIcon,
  // ButtonText,
  // StyledDescription,
  // FlexBetweenContainer,
  // SocialIconWrapper,
  // PricingAreaContainer,
  // DetailsWrapper,
  // BBInfoContainer,
  // BBInfoGrid,
  // BBInfoColSpan,
  // FooterBtnContainer,
  // SimilarItemsWrapper,
  // SimilarItemsList,
  // SeeMoreBtn,
} from "./Element";
// import {
//   CategoryText,
// } from "../Billboards/BillboardLists/Elements";
// import {
//   FlexCenterContainer,
//   FlexColumnContainer,
// } from "../common/Elements";
import Update from "../Update";
import { FlexColumnContainer } from "../common/Elements";
import { ListItemSecondaryAction } from "@mui/material";
import { LeftInfoText } from "../Home/Contact/Element";
// import Print from "../../assets/images/print-icon.svg";
// import Facebook from "../../assets/images/fb.svg";
// import Twitter from "../../assets/images/twitter.svg";
// import Instagram from "../../assets/images/instagram.svg";
// import {
//   useQuery
// } from "@apollo/client";
// import {
//   SCREENS_QUERY,
//   SCREEN_BY_ID_QUERY
// } from "../../schema/gqlSchema";
// import ImageSlider from "./Slider/slider";
// import BBItemList from "./ItemLists/ItemList";
// import { useMediaQuery } from "@mui/material";
// import SimilarItem from "./SimilarItem";
// import ContactPopup from "../ContactPopup";

const BillboardDetailComponent = ({ id, }) => {
  const totalStars = 5;
  const dotCount = 100;
  const dots = ".".repeat(dotCount);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const images = [
    logo3,
    logo3, 
    logo3,
  ];

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return <SliderArrow className="prev" onClick={onClick}>{"<"}</SliderArrow>;
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return <SliderArrow className="next" onClick={onClick}>{">"}</SliderArrow>;
  };
  

  const settingsMain = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    asNavFor: nav2,
    prevArrow: <CustomPrevArrow />, 
    nextArrow: <CustomNextArrow />, 
  };

  const settingsThumbnails = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect: true,
    asNavFor: nav1,
  };


  // const showSimiliarItems = useMediaQuery("(max-width:1200px)");
  // const { data } = useQuery(
  //   SCREEN_BY_ID_QUERY,
  //   {
  //     variables: { id },
  //     fetchPolicy: 'cache-and-network'
  //   }
  // );
  // const { data: similarItems } = useQuery(
  //   SCREENS_QUERY,
  //   {
  //     variables: { top: 3 },
  //     fetchPolicy: "network-only"
  //   }
  // );

  // const [open, setOpen] = useState(false);

  return (
    // <FlexColumnContainer>
    //   <ContactPopup open={open} onClose={() => setOpen(false)} />
    //   <MapContainer>
    //     <ImageSlider data={data} />
    //   </MapContainer>
    //   <Title style={{ color: 'white', paddingTop: '50px', marginLeft: '5%', marginRight: '5%', borderBottom: '1px #eaeaea solid' }}>{ data?.findScreenContent?.flatData?.title }</Title>
    //   <DetailsWrapper>
    //     <AddressWrapper>
    //       <BBItemList data={data} />
    //     </AddressWrapper>
    //     {
    //       !showSimiliarItems &&
    //       <PricingAreaContainer>
    //         <PhotoContainer>
    //           <Downloadbtn onClick={() => setOpen(true)}>Download Pricing</Downloadbtn>
    //           <Sharebtn onClick={() => setOpen(true)}>Share</Sharebtn>
    //         </PhotoContainer>
    //       </PricingAreaContainer>
    //     }
    //     <BBInfoContainer>
    //       <Title>BILLBOARD INFORMATION</Title>
    //       <BBInfoGrid>
    //         <FlexColumnContainer>
    //           <StyledDescription>Latitude</StyledDescription>
    //           <Value>
    //             {data?.findScreenContent?.flatData?.geolocation?.latitude || 'N/A'}
    //           </Value>
    //           <Border />
    //         </FlexColumnContainer>

    //         <FlexColumnContainer>
    //           <StyledDescription>Longitude</StyledDescription>
    //           <Value>
    //             {data?.findScreenContent?.flatData?.geolocation?.longitude || 'N/A'}
    //           </Value>
    //           <Border />
    //         </FlexColumnContainer>

    //         <FlexColumnContainer>
    //           <StyledDescription>Media Panel</StyledDescription>
    //           <Value>
    //             {data?.findScreenContent?.flatData?.mediaPanel || 'N/A'}
    //           </Value>
    //           <Border />
    //         </FlexColumnContainer>

    //         <FlexColumnContainer>
    //           <StyledDescription>Display size (H x W)</StyledDescription>
    //           <Value>
    //             {data?.findScreenContent?.flatData?.displaySize || 'N/A'}
    //           </Value>
    //           <Border />
    //         </FlexColumnContainer>

    //         <BBInfoColSpan>
    //           <StyledDescription>Lighting</StyledDescription>
    //           <Value>-</Value>
    //           <Border />
    //         </BBInfoColSpan>

    //         <BBInfoColSpan>
    //           <Title>Description</Title>
    //           <Location>
    //             {data?.findScreenContent?.flatData?.description || 'N/A'}
    //           </Location>

    //           <Border />
    //         </BBInfoColSpan>

    //         <BBInfoColSpan>
    //           <Title>Categories</Title>
    //           {
    //             data?.findScreenContent?.flatData?.tags?.map(tag => (
    //               <CategoryText>
    //                 {tag || 'N/A'}
    //               </CategoryText>
    //             ))
    //           }
    //           <Border />
    //         </BBInfoColSpan>

    //         <BBInfoColSpan>
    //           <Title>Media Availability</Title>
    //           <LatLong>
    //             <Container>
    //               <StyledDescription>Traffic volume</StyledDescription>
    //               <Value>
    //                 {data?.findScreenContent?.flatData?.footFall}<span style={{ color: "#fff" }}>(per month)</span>
    //               </Value>
    //             </Container>
    //             <Container>
    //               <StyledDescription>Availability</StyledDescription>
    //               <Value>
    //                 {data?.findScreenContent?.flatData?.availability}
    //               </Value>
    //             </Container>
    //           </LatLong>
    //         </BBInfoColSpan>

    //       </BBInfoGrid>

    //       <FlexBetweenContainer>
    //         <FooterBtnContainer>
    //           <FlexCenterContainer>
    //             <Downloadbtn style={{ margin: 0 }} onClick={() => setOpen(true)}>Download Pricing</Downloadbtn>
    //           </FlexCenterContainer>
    //           <FlexCenterContainer onClick={() => setOpen(true)}>
    //             <img src={Print} alt="printIcon" width={50}></img>
    //           </FlexCenterContainer>
    //         </FooterBtnContainer>
    //         <SocialIconWrapper>
    //           <SocialIcon src={Facebook} alt="Facebook" onClick={() => setOpen(true)}></SocialIcon>
    //           <SocialIcon src={Twitter} alt="Twitter" onClick={() => setOpen(true)}></SocialIcon>
    //           <SocialIcon src={Instagram} alt="Instagram" onClick={() => setOpen(true)}></SocialIcon>
    //         </SocialIconWrapper>
    //       </FlexBetweenContainer>
    //     </BBInfoContainer>
    //     {
    //       !showSimiliarItems &&
    //       <SimilarItemsWrapper>
    //         <Title>More Similar Items</Title>
    //         <Border />
    //         <SimilarItemsList>
    //           {
    //             similarItems?.queryScreenContents?.map(similarItem => <SimilarItem data={similarItem} />)
    //           }
    //         </SimilarItemsList>
    //         <Border />
    //         <SeeMoreBtn to={'/billboards'}>
    //           <ButtonText>See More</ButtonText>
    //         </SeeMoreBtn>
    //       </SimilarItemsWrapper>
    //     }
    //   </DetailsWrapper>
    //   <Update />
    // </FlexColumnContainer>
  <FlexColumnContainer>
    <Top>
      <PageNav>Home / Sales / Screens / Tahir's Screen</PageNav>
      <Cart>My Cart <GiShoppingCart className="icon" /></Cart>
    </Top>
    
    <Wrapper>
    <SliderContainer>
        <Slider {...settingsMain} asNavFor={nav2} ref={(slider) => setNav1(slider)} 
        style={{ height: "550px", width: "100%"}}>
          {images.map((image, index) => (
            <div key={index} style={{ position: "relative" }}>
              <SlideImage src={image} alt={`Slide ${index}`} />
            </div>
          ))}
        </Slider>
        
        <Slider {...settingsThumbnails} asNavFor={nav1} ref={(slider) => setNav2(slider)} 
        style={{ maxWidth: "580px", width: '100%', marginTop: "50px", textAlign: "center" }}>
          {images.map((image, index) => (
            <div key={index} >
              <img src={image} alt={`Thumbnail ${index}`} style={{ width: "100%"}} />
            </div>
          ))}
        </Slider>

        <CustomPrevArrow onClick={() => nav1?.slickPrev()} />
          <CustomNextArrow onClick={() => nav1?.slickNext()} />
      </SliderContainer>

      <BBInfo>
        <BBScreen>Tahir's Screen</BBScreen>
        <BBScreenID>Screen ID: sz2303132522494749</BBScreenID>

        <StarRate>
          {Array.from({ length: totalStars }, (_, index) => (
            <Star key={index} />
          ))}
        <BBReview>(3 reviews)</BBReview>
        </StarRate>
        <BBPriceLine>
            <BBPrice>1000 RM</BBPrice>
            <BBDiscount>2000 RM</BBDiscount>
            <BBDiscountCard>-50%</BBDiscountCard>
        </BBPriceLine>
      
        <BBLine>{dots}</BBLine>
          <BBAddress> Address <br>
          </br> 2 sided overhead Bridge Billboard at Federal Highway, Mid Valley
          </BBAddress>
        <BBLine>{dots}</BBLine>

          <BBTypeHead>TYPE</BBTypeHead>
          <BBType>Screen</BBType>
        <BBLine>{dots}</BBLine>

        <MediaStatus>MEDIA AVAILABILITY</MediaStatus>
        <MediaInfo>
          <TrafficVolume>
              Traffic Volume
            <TrafficVolumeText> <span>192,000,200  </span> &nbsp; (per month)</TrafficVolumeText>
          </TrafficVolume>
          <MediaAvailability>
          Availability 
          <TrafficVolumeText> <span>Subject to availability </span></TrafficVolumeText>
          </MediaAvailability>
        </MediaInfo>
        <BBLine>{dots}</BBLine>

        <Description>DESCRIPTION</Description>
          <LocationInfo>
            Located at Federal Highway KM 11.4, Shah Alam
          </LocationInfo>

          <DirectionInfo>
            Direction from Klang/Shah Alam heading towards Petaling Jaya/Kuala Lumpur
          </DirectionInfo>
          
          <NearbyInfo>
            3.4km from SACC Mall Shah Alam<br></br>
            3.5km to Istana Bukit Kayangan<br></br>
            3.7km to Lotus's Shah Alam<br></br>
            4km from Shah Alam AutoCity<br></br>
            5.1km to KTM Shah Alam<br></br>
            6.1km from I-City Shah Alam
          </NearbyInfo>
        
      
      </BBInfo>
    </Wrapper>
  </FlexColumnContainer>

  );
};

export default BillboardDetailComponent;
