import React, { useState } from 'react';
import { BBListContainer, BBListSortView, ResultCountText, SortBySelect, Text, VerticleLine } from './Elements';
import { FlexCenterContainer, FlexColumnContainer, FlexContainer } from '../../common/Elements';
import { MenuItem } from '@mui/material';
import FilterComponent from './FilterView';
import BillboardItem from './BillboardItem';
import UpdateComponent from '../../Update';
import { useMediaQuery } from '@mui/material';

const BillboardLists = ({ loading, error, data }) => {
  const isMobile = useMediaQuery("(max-width:960px)");
  const isTinyScreen = useMediaQuery("(max-width:500px)");
  const [sortBy, setSortBy] = useState('RECOMMENDED');

  const handleSortByChange = (event) => {
    if (event?.target?.value) {
      setSortBy(event.target.value);
    }
  };

  return (
    <BBListContainer>
      <BBListSortView>
        <FlexContainer>
          <ResultCountText> {data?.queryScreenContents?.length} </ResultCountText>
          <Text>&nbsp; Results Found</Text>
        </FlexContainer>
        <FlexCenterContainer>
          <VerticleLine />
          <Text style={{ paddingLeft: isTinyScreen ? '' : '10px' }}> Sorted By: </Text>
          <SortBySelect
            style={{ color: 'white' }}
            value={sortBy}
            onChange={handleSortByChange}
          >
            <MenuItem value="RECOMMENDED">
              RECOMMENDED
            </MenuItem>
            <MenuItem value="DATE">
              DATE
            </MenuItem>
          </SortBySelect>
        </FlexCenterContainer>
      </BBListSortView>
      <FlexContainer style={{ paddingTop: '20px' }}>
        {
          !isMobile && <FilterComponent />
        }
        <FlexColumnContainer style={{ paddingLeft: isMobile ? '' : '30px', width: '100%' }}>
          {
            error ?
              (
                <FlexCenterContainer>
                  <h1 style={{ color: 'white' }}> Error loading data! </h1>
                </FlexCenterContainer>
              )
              : loading ?
                (
                  <FlexCenterContainer>
                    <h1 style={{ color: 'white' }}> Please wait... </h1>
                  </FlexCenterContainer>
                )
                : data?.queryScreenContents?.length > 0 ?
                  data?.queryScreenContents?.map(screen => <BillboardItem screen={screen} />)
                  : <FlexCenterContainer>
                    <h1 style={{ color: 'white' }}> No Screen Found </h1>
                  </FlexCenterContainer>
          }
        </FlexColumnContainer>
      </FlexContainer>
      <UpdateComponent />
    </BBListContainer>
  );
}

export default BillboardLists;
