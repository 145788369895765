import styled from "styled-components";
import { SectionContainer } from "../Section2/Element";
import { FlexColumnContainer } from "../../common/Elements";

export const AboutUsContainer = styled(SectionContainer)`
  
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

export const AboutUsContentContainer = styled(FlexColumnContainer)`
  flex: 1;
  max-width: 520px;
  padding-left: 140px;
  padding-right: 50px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; /* Change font family */

  @media screen and (max-width: 1300px) {
    margin-right: 0;
    padding: 40px;
    max-width: none;
  }

  @media screen and (max-width: 600px) {
    padding: 20px;
  }
`;

export const AboutUsParagraph = styled.p`
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.75px;
  line-height: 2;
  text-align: justify;

  @media screen and (max-width: 1250px) {
    line-height: 1.5;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;