import React from 'react';
import NavBar from '../NavBar';
import { MainContainer } from './Elements';
import Footer from './Footer';

const BaseComponent = ({ children }) => {
    return (
        <MainContainer>
            <NavBar/>
            {children}
            <Footer />
        </MainContainer>
    );
}

export default BaseComponent;
