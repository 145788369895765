import { styled } from "styled-components";
import { FlexColumnContainer } from "../../../common/Elements";

export const FilterContainer = styled(FlexColumnContainer)`
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    padding: 16px;
    width: 160px;
    height: max-content;
    @media screen and (max-width: 960px) {
        display: none;    
    }
`;

export const Label = styled.span`
    color: #EAEAEA;
    font-size: 14px;
    padding-bottom: 10px;
`;

export const ClearButton = styled.div`
    cursor: pointer;
    color: #007BFF;
    font-size: 13px;
`;

export const ApplyButton = styled.div`
    cursor: pointer;
    color: white;
    background-color: #FC1481;
    font-size: 13px;
    padding: 8px 14px;
    border-radius: 6px;
`;

export const Divider = styled.div`
    background-color: #EAEAEA;
    width: 100%;
    height: 1.5px;
    margin: 15px 0px;
`;
  