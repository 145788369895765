import React from "react";
import { SectionContainer, LogoRow, LogoColumn, Image, Span } from "./Element";
import logo1 from "../../../assets/images/Logo ZoomerMedia.png";
import logo2 from "../../../assets/images/Logo KTMB.png";
import logo3 from "../../../assets/images/Logo MooveMedia.jpg";
import logo4 from "../../../assets/images/Logo SistemPerintis.png";
import logo5 from "../../../assets/images/Logo SkyBlue.png";
import logo6 from "../../../assets/images/Logo SegiFresh.png";

const Section3 = () => {
  return (
    <SectionContainer>
      <Span>Our Clients</Span>
      <br />
      <LogoRow>
        <LogoColumn style={{ width: '20%'}}>
        <a href="http://www.zoomermedia.com.my/" target="_blank" rel="noopener noreferrer">
            <Image src={logo1} alt="Image 1" />
          </a>
        </LogoColumn>
        <LogoColumn>
        <a href="https://www.ktmb.com.my/" target="_blank" rel="noopener noreferrer">
            <Image src={logo2} alt="Image 1" />
          </a>
        </LogoColumn>
        <LogoColumn>
        <a href="https://www.moovemedia.com.sg/" target="_blank" rel="noopener noreferrer">
            <Image src={logo3} alt="Image 1" />
          </a>
        </LogoColumn>
      </LogoRow>
      <LogoRow>
        <LogoColumn>
        <a href="https://www.sistemperintis.net/" target="_blank" rel="noopener noreferrer">
            <Image src={logo4} alt="Image 1" />
          </a>
        </LogoColumn>
        <LogoColumn>
        <a href="https://www.skybluemedia.my/" target="_blank" rel="noopener noreferrer">
            <Image src={logo5} alt="Image 1" />
          </a>
        </LogoColumn>
        <LogoColumn >
        <a href="https://segigroup.com/" target="_blank" rel="noopener noreferrer">
            <Image src={logo6} alt="Image 1" style={{ width: '110%'}}/>
          </a>
        </LogoColumn>
      </LogoRow>
    </SectionContainer>
  );
};

export default Section3;
