import React, { useState } from 'react'
import {
    BBAddressLabel,
    BBAddressText,
    BBImage,
    BBItemContainer,
    BBItemContentContainer,
    BBItemFooterContainer,
    CategoryLabel,
    CategoryText,
    Divider,
    HoverView,
    ImageContainer,
    LocationText,
    PriceText
} from './Elements'
import PriceTag from '../../../assets/images/price_tag.svg';
import PinPink from '../../../assets/images/pin_pink.svg';
import BuildingImg from '../../../assets/images/building.png';
import {
    FlexCenterContainer,
    FlexColumnContainer,
    FlexContainer
} from '../../common/Elements';
import { useMediaQuery } from '@mui/material';

const BillboardItem = ({ screen }) => {
    const isMobile = useMediaQuery("(max-width:960px)");
    const isTinyScreen = useMediaQuery("(max-width:500px)");
    const [isHover, setIsHover] = useState(false);

    return (
        <BBItemContainer to={`/billboard/${screen.id}`} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            { isHover && <HoverView /> }
            <BBItemContentContainer>
                <FlexColumnContainer style={{ flex: 1 }}>
                    <FlexContainer style={{ flexDirection: isMobile ? 'column' : '' }}>
                        <PriceText>
                            <img src={PriceTag} alt='price tag  icon'></img> &nbsp;
                            {screen?.flatData?.price || 'N/A'} RM / Month
                        </PriceText>

                        <LocationText
                            style={{
                                paddingLeft: isMobile ? '' : '20px',
                                paddingTop: isMobile ? '10px' : ''
                            }}
                        >
                            <img src={PinPink} alt='pin icon'></img> &nbsp;
                            {screen?.flatData?.address || 'N/A'}
                        </LocationText>
                    </FlexContainer>
                    <BBAddressLabel>
                        Screen Name:
                    </BBAddressLabel>
                    <BBAddressText>
                        {screen?.flatData?.title || 'N/A'}
                    </BBAddressText>
                    <BBAddressLabel>Address : </BBAddressLabel>
                    <BBAddressText>{screen?.flatData?.address || 'N/A'}</BBAddressText>
                    {/* <img src={NavigationIcon} alt='navigation icon' width='30px' height='30px' style={{ paddingTop: '20px', paddingBottom: '15px' }} /> */}
                </FlexColumnContainer>
                <ImageContainer>
                    <BBImage src={screen?.flatData?.img[0]?.url || BuildingImg} alt='billbord img' />
                </ImageContainer>
            </BBItemContentContainer>
            <Divider />
            <BBItemFooterContainer>
                <FlexCenterContainer style={{ whiteSpace: isTinyScreen ? 'nowrap' : ''}}>
                    <CategoryLabel>Category : &nbsp;</CategoryLabel>
                    {
                        screen?.flatData?.tags?.map(tag => <CategoryText>{tag || 'N/A'}</CategoryText>)
                    }
                </FlexCenterContainer>
                <FlexCenterContainer style={{ whiteSpace: isTinyScreen ? 'nowrap' : '', paddingTop: isTinyScreen ? '14px' : ''}}>
                    <CategoryLabel>Availability : &nbsp;</CategoryLabel>
                    <CategoryText>{screen?.flatData?.availability || 'N/A'}</CategoryText>
                </FlexCenterContainer>
            </BBItemFooterContainer>
        </BBItemContainer>
    )
}

export default BillboardItem;
