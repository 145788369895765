import React, { useState } from "react";
import {
  ActionContainer,
  FormBaseContainer,
  FormContainer,
  FormLeftContainer,
  FormRightContainer,
  FormRightLabel,
  GridContainer,
  Img,
  InputContainer,
  InputLabel,
  LeftInfoText,
  MessageTextArea,
  SocialIcons,
  Submitbtn,
  SuccessMessage,
} from "./Element";
import Phone from "../../../assets/images/phone.svg";
import Mail from "../../../assets/images/mail.svg";
import Facebook from "../../../assets/images/facebook.svg";
import Github from "../../../assets/images/github.svg";
import Twitter from "../../../assets/images/twit.svg";
import { FlexContainer } from "../../common/Elements";
import { TextField } from "@mui/material";
import axios from 'axios';
// import { SuccessMessage } from "./Element";

const FormSection = () => {
  //const url = 'https://email.snipeads.com/messages';
  const url = process.env.REACT_APP_EMAIL_API_URL; //changed this line to access URL stored in env
  console.log(process.env.REACT_APP_EMAIL_API_URL); // Add console.log to check the value of REACT_APP_EMAIL_API_URL
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  const [successMessage, setSuccessMessage] = useState(false);

  const handleOnSubmit = async () => {
    try {
      const result = await axios.post(url, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        subject: formData.subject,
        note: formData.message,
        contactNumber: formData.phone
      });
      console.log(result);
      setSuccessMessage(true);
      // Clear the form fields
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleOnTextChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  }

  return (
    <FormBaseContainer id="contact">
      <FormContainer>
        <FormLeftContainer>
          <LeftInfoText>Contact Information</LeftInfoText>
          <LeftInfoText>
            <Img src={Phone}></Img>+60 16-330 5563
          </LeftInfoText>
          <LeftInfoText>
            <Img src={Mail}></Img> info@snipeads.com
          </LeftInfoText>
          <SocialIcons>
            <img src={Facebook}></img>
            <img src={Github}></img>
            <img src={Twitter}></img>
          </SocialIcons>
        </FormLeftContainer>

        <FormRightContainer>
          <FormRightLabel>Send us a message</FormRightLabel>
          <GridContainer>
            <InputContainer>
              <InputLabel> First name </InputLabel>
              <TextField id="firstName" variant="outlined" value={formData.firstName} onChange={handleOnTextChange} />
            </InputContainer>

            <InputContainer>
              <InputLabel> Last name </InputLabel>
              <TextField id="lastName" variant="outlined" value={formData.lastName} onChange={handleOnTextChange} />
            </InputContainer>

            <InputContainer>
              <InputLabel> Email </InputLabel>
              <TextField id="email" variant="outlined" value={formData.email} onChange={handleOnTextChange} />
            </InputContainer>

            <InputContainer>
              <FlexContainer style={{ justifyContent: 'space-between' }}>
                <InputLabel> Phone </InputLabel>
                <InputLabel style={{ color: '#6B7280' }}> Optional </InputLabel>
              </FlexContainer>
              <TextField id="phone" variant="outlined" value={formData.phone} onChange={handleOnTextChange} />
            </InputContainer>
          </GridContainer>

          <InputContainer style={{ paddingTop: '20px' }}>
            <InputLabel> Subject </InputLabel>
            <TextField id="subject" variant="outlined" value={formData.subject} onChange={handleOnTextChange} />
          </InputContainer>

          <InputContainer style={{ paddingTop: '20px' }}>
            <FlexContainer style={{ justifyContent: 'space-between' }}>
              <InputLabel> Message </InputLabel>
              <InputLabel style={{ color: '#6B7280' }}> Max. 500 characters </InputLabel>
            </FlexContainer>
            <MessageTextArea id="message" minRows={7} value={formData.message} onChange={handleOnTextChange} />
          </InputContainer>

          <ActionContainer>
            <Submitbtn onClick={handleOnSubmit}>Submit</Submitbtn>
          </ActionContainer>
          
          {successMessage && (
            <SuccessMessage>
              Your message has been sent successfully!
            </SuccessMessage>
          )}
        </FormRightContainer>
      </FormContainer>
    </FormBaseContainer>
  );
};

export default FormSection;