import styled from "styled-components";
import backgroundImg from "../../../assets/images/background.svg";
import { Link as LinkR } from "react-router-dom";

export const Background = styled.div`
  background-image: url(${backgroundImg});
  background-color: #0b0121;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    height: 80vh;
  }
`;

export const Card = styled.div`
  padding: 0px 5.5rem;
  height: 25rem;
  border-radius: 2rem;
  box-shadow: inset 0px 0.5px 0px rgba(255, 255, 255, 0.5);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: drop-shadow(0px -2px 10px rgba(233, 223, 255, 0.4))
    drop-shadow(0px -2px 40px rgba(187, 155, 255, 0.16));
  width: 35rem;
  margin-left: 5%;
  margin-top: 10%;
  

  @media screen and (max-width: 768px) {
    padding: 0px 1.5rem;
    height: auto;
    width: auto;
    max-width: 90%;
  }
`;

export const StyledParagraph = styled.p`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 3rem;
  color: #ffffff;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
    margin-top: auto;
  }

  @media screen and (max-width: 450px) {
    font-size: 1.7rem;
    text-align: center;
  }
`;

export const BillboardText = styled.a`
  color: #fc1481;
  text-decoration: none;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const ContentContainer = styled.div`
  @media screen and (min-width: 768px) {
    flex: 1;
  }
`;

export const SearchBox = styled.div`
  box-sizing: border-box;
  width: 25rem;
  height: 5.6rem;
  border: 1px solid #ffffff;
  border-radius: 0.5rem;
  margin-top: 1rem;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const SearchButton = styled(LinkR)`
  background: #fc1481;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 19rem;
  height: 3.6rem;
  margin-top: 1rem;
  margin-left: 7rem;
  border: none;
  color: #ffffff;
  font-family: Avenir;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  transition: background 0.3s ease; /* Add transition for smoother color change */

  /* Change background color on hover */
  &:hover {
    background: #ff6ab4; /* Change to desired color */
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: auto;
  }
`;

export const SmallText = styled.p`
  font-family: Avenir;
  font-size: 0.65rem;
  margin-left: 7rem;
  color: #777777;
  padding-top: 0.3rem;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    margin-left: auto;
  }
`;
