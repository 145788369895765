import {StyledHomeIcon} from '../components/NavBar/Elements.js';

export const routes = [
  {
    id: "home",
    name: "/",
    label: <StyledHomeIcon />,
  },
  {
    id: "about",
    name: "/about",
    label: "About",
  },
  {
    id: "mediaOwner",
    name: "/media owner",
    label: "Media Owner",
  },
  {
    id: "contact",
    name: "/contact",
    label: "Contact",
  },
];

export const screenTypes = [
  { 
    value: 'BULKHEAD_DISPLAY',
    label: 'Bulkhead Display'
  },
  {
    value: 'DIGITAL_BILLBOARD',
    label: 'Digital Billboard'
  },
  {
    value: 'DIGITAL_LAMP_POST',
    label: 'Digital Lamp Post'
  },
  {
    value: 'KIOSK_SCREEN',
    label: 'Kiosk Screen'
  },
  {
    value: 'LCD_SCREEN',
    label: 'LCD Screen'
  },
  {
    value: 'LED_SCREEN',
    label: 'LED Screen'
  }
];

export const dashURL = {
  login: 'https://dash.staging.snipeads.com/',
  register: 'https://dash.staging.snipeads.com/register'
}

export const states = [
  'Johor',
  'Kedah',
  'Kelatan',
  'Malacca',
  'Selangor',
  'Sabah',
  'Sarawak',
  'Penang'
]

export {
  StyledHomeIcon,
};