import styled from "styled-components";

export const Div = styled.div`
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(30,7,83,1) 100%, rgba(0,212,255,1) 100%);
  // height: 90vh;
  padding-top: 20px;
  @media screen and (max-width: 960px) {
    // height: 110vh;
    padding: 20px 20px;
  }
`;

export const Span = styled.div`
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; /* Change font family */
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  padding-top: 40px;
  @media screen and (max-width: 960px) {
    font-size: 1.5em;
    white-space: nowrap;
  }
`;

export const SpanText = styled.div`
  color: #FC1481;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; /* Change font family */
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  padding-top: 10px;

  @media screen and (max-width: 960px) {
    font-size: 1em;
  }
`;

export const Input = styled.input`
  width: 320px;
  height: 40px;
  margin-top: 30px;
  border-radius: 5px;
  border: 1px solid #d1d5db;
  background: #ffffff;
  padding-left: 20px;

  &:focus {
    outline: none;
  }
  @media screen and (max-width: 768px) {
    width: 230px;
  }
`;

export const Card = styled.div`
  height: 15rem;
  border-radius: 2rem;
  box-shadow: inset 0px 0.5px 0px rgba(255, 255, 255, 0.5);
  border: none;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: drop-shadow(0px -2px 10px rgba(233, 223, 255, 0.4))
    drop-shadow(0px -2px 40px rgba(187, 155, 255, 0.16));
  margin: 0 0 80px 70px;
  width: 90%;
  text-align: center;

  @media screen and (max-width: 768px) {
    padding: 0px 1rem;
    margin: 0 1rem;
    height: auto;
    max-width: 100%;
  }
`;
