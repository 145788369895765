import React, { useState } from "react";
import laptopImg6 from "../../../assets/images/Media 6.jpg";
import laptopImg5 from "../../../assets/images/Media 5.jpg";
import laptopImg8 from "../../../assets/images/Media 8.jpg";
import laptopImg9 from "../../../assets/images/Media 9.jpg";
import {
  MediaOwnerContainer,
  Divider,
  SubHeader,
  SubHeaderS,
  ImageGridContainer,
  ImageContainer,
  Image,
  ContactBtn,
  MediaOwnerBody,
  MediaOwnerContentsContainer,
} from "./Element";

import { Header } from "../Section2/Element";
import { AboutUsParagraph } from "../Section4-AboutUs/Element";

const Section5 = () => {
  const scrollToContact = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  }; //to redirect contact button
  
  return (
    <MediaOwnerContainer id="mediaOwner">
      <Header> Media Owner </Header>
      <Divider />
      <SubHeader>
        One stop solution for all of your Digital-out-of-Home needs
      </SubHeader>
      <SubHeaderS>
        Online digital signage platform that helps you automate your business operations
      </SubHeaderS>
      <MediaOwnerBody>
      <ImageGridContainer>
        <ImageContainer style={{border: '1px solid black', borderTopLeftRadius: '10px' }}>
          <Image style={{ borderTopLeftRadius: '10px'}} src={laptopImg6} alt="Image 6" />
        </ImageContainer>
        <ImageContainer style={{border: '1px solid black', borderTopRightRadius: '10px' }}>
          <Image style={{ borderTopRightRadius: '10px' }} src={laptopImg5} alt="Image 5" />
        </ImageContainer>
        <ImageContainer style={{border: '1px solid black', borderBottomLeftRadius: '10px' }}>
          <Image style={{ borderBottomLeftRadius: '10px' }} src={laptopImg8} alt="Image 8" />
        </ImageContainer>
        <ImageContainer style={{border: '1px solid black', borderBottomRightRadius: '10px' }}>
          <Image style={{ borderBottomRightRadius: '10px' }} src={laptopImg9} alt="Image 9" />
        </ImageContainer>
    </ImageGridContainer>

        <MediaOwnerContentsContainer>
          <Header>Make more money from your screens</Header>
          <AboutUsParagraph>
            Assign a portion of your inventory to Snipeads and our DSP will generate additional sales for you. Your screens will appear on our DSP and our advertisers can launch programmatic campaigns to your screens. Your earnings will be transferred to your account at the end of each month through online payment gateway.
          </AboutUsParagraph>
          {/* Call scrollToContact function on button click */}
          <ContactBtn onClick={scrollToContact}>Contact</ContactBtn>
        </MediaOwnerContentsContainer>
      </MediaOwnerBody>
    </MediaOwnerContainer>
  )
};

export default Section5;
