import React from "react";
import {
  ContentHolder,
  Header,
  Paragraph,
  SectionContainer
} from "./Element";
import PlatformPreview from "./PlatformPreview";
import { FlexCenterContainer } from "../../common/Elements";

const Section2 = () => {
  return (
    <SectionContainer>
      <FlexCenterContainer style={{ flex: 1, maxWidth: '800px' }}>
        <PlatformPreview />
      </FlexCenterContainer>
      <ContentHolder>
        <Header>DOOH platform for the Modern World</Header>
        <Paragraph>
          Snipeads is a Digital-out-of-Home (DOOH) platform for Media Owners and
          Advertisers to transact online. Snipeads Media Owner Portal allows
          screen owners to manage the content of their screens, keep track of
          their inventory, automate their booking, campaign management, billing
          and reporting all in one dashboard. Snipeads also allows advertisers
          to launch their marketing campaigns by targeting their audiences
          through the screens available on Snipeads platform.
        </Paragraph>
      </ContentHolder>
    </SectionContainer>
  );
};

export default Section2;
