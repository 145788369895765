import styled from "styled-components";
import bgImage from "../../assets/images/bg.jpg";
import { FlexCenterContainer } from "../common/Elements";

export const Container = styled(FlexCenterContainer)`
    justify-content: flex-start;
    padding-left: 100px;
    background-color: #0b0121
    background-size: cover;
    background-image: url(${bgImage});
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: 900px) {
        flex-direction: column;
        padding-left: 10px;
        padding-bottom: 20px;
    }

`;