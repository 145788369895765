import React from "react";
import BaseComponent from "../components/common/BaseComponent";
import HomeComponent from "../components/Home";

const Home = () => {
  return (
    <BaseComponent>
      <HomeComponent />
    </BaseComponent>
    // <BaseComponent>
    //   <HomeComponent />
    // </BaseComponent>
  );
};

export default Home;
