import React from "react";
import {
  DropdownField,
  DropdownContainer,
  DropdownMenu,
  DropdownMenuItem,
} from "./Element";
import { screenTypes } from "../../../common/constants";

const CustomDropdown = ({ type, setType }) => {

  function handleSelect(event) {
    setType(event.target.value);
  }

  return (
    <DropdownContainer>
      <DropdownField value={type} onChange={handleSelect}>
        <option value="">
          Select Media Type
        </option>
        {screenTypes.map((screenType) => (
          <option key={screenType.value} value={screenType.value}>
            {screenType.label}
          </option>
        ))}
      </DropdownField>
      <DropdownMenu>
        {screenTypes.map((screenType) => (
          <DropdownMenuItem  key={screenType.value}>{screenType.label}</DropdownMenuItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default CustomDropdown;
