import React from "react";
import { FlexColumnContainer } from "./Elements";
import { Contact, Legalese, ContentHolder, ContentColumn, AgreementLink, Address, SocialIcon, IconContainer, Copyright } from "./FooterElements";
import Logo from "./Logo";
import { useMediaQuery } from '@mui/material';
import { FaInstagram, FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  const isMobile = useMediaQuery("(max-width:960px)");
  return (
    <FlexColumnContainer style={{ width: "100%" }}>
      <ContentHolder>
        <ContentColumn>
          <Contact>
            <SocialIcon>
            <span style={{ fontSize: "24px",  paddingBottom: "20px", display: "inline-block"}}>
              Social Links
            </span>

            <IconContainer>
              <FaInstagram className="social-icon"/>
              <FaYoutube className="social-icon"/>
              <FaSquareXTwitter className="social-icon"/>
            </IconContainer>
          </SocialIcon>
            <span style={{ fontSize: "24px", paddingBottom: "20px" }}>
              Contact
            </span>
            <span>Snipeads</span>
            <span>+60 16-330 5563</span>
            <span>info@snipeads.com</span>
          </Contact>

          <Contact>
            <span style={{ fontSize: "24px", paddingBottom: "20px" }}>
              Address
            </span>
            <Address>
            Tower 1 G, Mercu Mustapha Kamal, Neo Damansara, Jalan PJU 8/1, 
            Damansara Perdana, 47820 Petaling Jaya, Selangor
            </Address>
          </Contact>
        </ContentColumn>

        <Legalese>
          <AgreementLink href="https://snipeads.com/privacy-policy/">
            <span>Privacy Policy</span>
          </AgreementLink>
          <AgreementLink href="https://snipeads.com/terms-and-conditions/">
            <span>Terms and Conditions</span>
          </AgreementLink>
        </Legalese>

      </ContentHolder>
        <Copyright href="https://snipeads.com/privacy-policy/">
          <span>© 2024 TRACTIVE SDN BHD. All RIGHT RESERVED</span>
        </Copyright>
    </FlexColumnContainer>
  );
};

export default Footer;