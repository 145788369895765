import React from 'react';
import BaseComponent from '../components/common/BaseComponent';
import BillboardsComponent from '../components/Billboards';

const Billboards = () => {
  return (
    <BaseComponent>
      <BillboardsComponent />
    </BaseComponent>
  )
}

export default Billboards;