import styled from "styled-components";
import bgImage from "../../../assets/images/bg.jpg";
import { FlexColumnCenterContainer } from "../../common/Elements";

export const SectionContainer = styled(FlexColumnCenterContainer)`
  height: 90vh;
  background-image: url(${bgImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 960px) {
    padding: 20px;
    text-align: center;
  }
`;

export const Span = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; /* Change font family */
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 10px;
  color: #ffffff;
  @media screen and (max-width: 960px) {
    font-size: 1.5em;
  }
`;

export const LogoRow = styled.div`
  display: flex;
  margin-top: 5%;
  width: 57%;
  height: 18%;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    width: auto;
    height: auto;
    justify-content: center;
    align-items: center;
  }
`;

export const LogoColumn = styled.div`
    float: left;
    width: 25.33%;
    padding: 6px;
    margin: auto;
  }
`;

export const Image = styled.img`
    display: cover;
    width: 100%;
    margin: auto;
    align-items: center;
  }
`;
