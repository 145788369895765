import React from "react";
import { Header } from "../Section2/Element";
import { 
  FlexCenterContainer
} from "../../common/Elements";
import { 
  AboutUsContainer,
  AboutUsContentContainer, 
  AboutUsParagraph
} from "./Element";
import AboutUsPreview from "./AboutUsPreview";

const Section4 = () => {

  return (
    <AboutUsContainer id="about">
      <AboutUsContentContainer>
        <Header>About Us</Header>
        <AboutUsParagraph>
          Snipeads is a Digital-out-of-Home (DOOH) platform developed and
          operated by Tractive Sdn Bhd. The platform solves one of the main
          problems of the DOOH industry in Malaysia, the ability to buy and sell
          advertising space on outdoor digital screens. Currently many media
          owners are limited with the software they use, and can't connect their
          screens to programmatic platforms to generate more sales. Snipeads
          enables media owners to easily manage their inventory and allow
          advertisers to place their ad content on their screens. At the same
          time, Snipeads allows advertisers to get access to large network of
          outdoor screens and manage their DOOH campaign in one integrated
          platform.
        </AboutUsParagraph>
      </AboutUsContentContainer>
      <FlexCenterContainer style={{ flex: 1, maxWidth: '800px' }}>
        <AboutUsPreview />
      </FlexCenterContainer>
    </AboutUsContainer>
  );
  // return (
  //   <Div id="about">
  //     <ContentHolder>
  //       <Header>About Us</Header>
  //       <Paragraph>
  //         Snipeads is a Digital-out-of-Home (DOOH) platform developed and
  //         operated by Tractive Sdn Bhd. The platform solves one of the main
  //         problems of the DOOH industry in Malaysia, the ability to buy and sell
  //         advertising space on outdoor digital screens. Currently many media
  //         owners are limited with the software they use, and can't connect their
  //         screens to programmatic platforms to generate more sales. Snipeads
  //         enables media owners to easily manage their inventory and allow
  //         advertisers to place their ad content on their screens. At the same
  //         time, Snipeads allows advertisers to get access to large network of
  //         outdoor screens and manage their DOOH campaign in one integrated
  //         platform.
  //       </Paragraph>
  //     </ContentHolder>
  //     <ImageContainer>
  //       <Image1 src={Blob} alt="Blob"></Image1>
  //       <Image2 src={Building} alt="Building"></Image2>
  //     </ImageContainer>
  //   </Div>
  // );
};
export default Section4;
