import styled from "styled-components";
import arrow from "../../../assets/images/down-arrow.png";

// Styled components
export const DropdownField = styled.select`
  appearance: none;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 20px 20px;
  position: relative;
  width: 100%;
  height: 56px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #eff0f7;
  font-size: 14px;

  &:focus {
    outline: none;
  }
`;

export const Span = styled.span``;

export const DropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  padding: 8px;
  margin-top: 4px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  z-index: 10;
  width: 100%;
`;

export const DropdownMenuItem = styled.div`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;
