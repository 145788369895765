import React from "react";
import { Div, Span, Input, Card, SpanText } from "./Element";
import { Submitbtn } from "../Contact/Element";

const Subscrption = () => {
  return (
    <Div>
      <Card>
        <Span>LET'S GET STARTED</Span>
        <SpanText>
          Do you need customized Digital Signage?
        </SpanText>
        <Input
          type="text"
          id="email"
          name="email"
          placeholder="Enter your email"
        ></Input>
        <span style={{ paddingLeft: "10px" }}>
          <Submitbtn>Subscribe</Submitbtn>
        </span>
      </Card>
    </Div>
  );
};

export default Subscrption;
