import { gql } from '@apollo/client';

export const SCREENS_QUERY = gql`
  query GetScreens(
    $top: Int,
    $skip: Int,
    $search: String,
    $filter: String
  ) {
    queryScreenContents(
      top: $top,
      skip: $skip,
      search: $search,
      filter: $filter
    ) {
      id,
      status,
      statusColor,
      newStatus,
      flatData {
        title,
        geolocation,
        tags,
        displaySize,
        description,
        img {
          url,
        }
        address,
        mapImg  {
          url,
        },
        mediaPanel
        availability
        price
      }
    }
  }
`;

export const SCREEN_BY_ID_QUERY = gql`
  query GetScreenById($id:String!) {
    findScreenContent(id:$id) {
      id,
      status,
      statusColor,
      newStatus,
      flatData {
        title,
        geolocation,
        tags,
        displaySize,
        description,
        footFall,
        img {
          url,
        }
        address,
        mapImg  {
          url,
        },
        mediaPanel,
        price,
        state,
        screenType,
        sort,
        availability
      }
    }
}
`;
