import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const Div = styled.div``;

export const Img = styled.img``;

export const Span = styled.span``;

export const FlexContainer = styled(Div)`
  display: flex;
`;

export const FlexColumnContainer = styled(FlexContainer)`
  flex-direction: column;
`;

export const FlexSpaceBetweenContainer = styled(FlexContainer)`
  justify-content: space-between;
`;

export const MainContainer = styled(Div)`
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(30,7,83,1) 100%, rgba(0,212,255,1) 100%);
  background-size: 100%;
  align-items: center;
  scroll-behavior: smooth;

  @media screen and (max-width: 950px) {
    background-size: cover;
  }
`;

export const FlexCenterContainer = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
`;

export const FlexColumnCenterContainer = styled(FlexCenterContainer)`
  flex-direction: column;
`;

export const H2Header = styled.h2`
  font-size: 38px;
  line-height: 46px;
  letter-spacing: 0px;
  font-weight: 400;
  text-transform: none;
`;

export const ButtonText = styled.span`
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0px;
  font-weight: 400;
  text-transform: none;
`;

export const SpaceArea = styled(Div)`
  height: 300px;
  background: transparent;
`;

export const SectionContainer = styled(FlexCenterContainer)`
  width: 100%;
  background: white;
`;

export const SectionWrapper = styled(FlexColumnContainer)`
  max-width: 1200px;
  padding: 80px 50px;
  flex: 1 1 auto;

  @media screen and (max-width: 500px) {
    padding: 50px 30px;
  }
`;

export const MainText = styled.span`
  font-size: 34px;
  font-weight: 500;
  text-align: lefSt;

  @media screen and (max-width: 500px) {
    font-size: 26px;
  }
`;

export const SubText = styled.span`
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
`;

export const SubHeader = styled(SubText)`
  font-weight: bold;
  font-size: 22px;
  padding-top: 30px;
  padding-bottom: 10px;
`;

export const LinkButton = styled(LinkR)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fc1481;
  width: max-content;
  padding: 20px;
  color: white;
  font-weight: bold;
  text-decoration: none;

  @media screen and (max-width: 500px) {
    align-self: center;
  }
`;

export const ScrollLinkButton = styled(LinkS)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #104660;
  width: max-content;
  padding: 20px;
  color: white;
  font-weight: bold;
  text-decoration: none;

  @media screen and (max-width: 500px) {
    align-self: center;
  }
`;
